import { atom } from 'recoil'
import { Lang } from '@sh/enums'
import { Store } from '@sh/models/classes'
import { AdAccount } from '@sh/models/interfaces'
import { LoadingStatus } from 'lib/enum'

type AdAccountList = {
  accounts: AdAccount[]
  loadingStatus: LoadingStatus
}

type SettingsHistoryItem = {
  desc: string
  store: Store
  ts: number
}

export const appInstalled = atom({
  key: 'app-installed',
  default: false,
})

export const lang = atom({
  key: 'settings-lang',
  default: Lang.Ja,
})

/** State changes made to store */
export const settingsHistory = atom({
  key: 'settings-history',
  default: [] as SettingsHistoryItem[],
})

export const accountsMapToService = atom({
  key: 'settings-accounts-map-to-service',
  default: {} as Record<string, AdAccount[]>,
})
