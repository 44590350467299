import { round } from '@sh/lib/calc'
import { AdData, GAData } from '@sh/models/classes'
import { selector } from 'recoil'
import * as data from './data'


// TODO consider currency
const TEMP_CURRENCY = 'JPY'
const roundCurr = (n:number) => TEMP_CURRENCY==='JPY'
  ? round(n, 0)
  : round(n, 2)

const getTotalCampaignStats = (campaigns:AdData[]) => {
  const stats = {
    impressions   : 0,
    clicks        : 0,
    spend         : 0,
    conversions   : 0,
    revenue       : 0,
    CPM           : 0,
    CPC           : 0,
    ROAS          : 0,
    conversionRate: 0,
    formattedCPM           : '',
    formattedCPC           : '',
    formattedROAS          : '',
    formattedConversionRate: '',
  }

  for (const c of campaigns) {
    stats.impressions += c.impressions
    stats.clicks += c.clicks
    stats.spend += c.spend
    stats.conversions += c.conversions
    stats.revenue += c.conversionValue
  }
  stats.CPM = stats.spend/(stats.impressions/1000)
  stats.CPC = stats.spend/stats.clicks
  stats.ROAS = stats.revenue/stats.spend
  stats.conversionRate = stats.conversions / stats.clicks
  stats.formattedCPM = '￥' + roundCurr(stats.CPM)
  stats.formattedCPC = '￥' + roundCurr(stats.CPC)
  stats.formattedROAS = roundCurr(stats.ROAS).toString()
  stats.formattedConversionRate = round(stats.conversionRate).toString() + '%'

  return stats
}

export const selectTotalCampaignStats = selector({
  key: 'select-total-campaign-stats',
  get: ({get}) => {

    return []
    // const fbCampaigns = get(data.fbCampaigns)
    // const googleCampaigns = get(data.googleCampaigns)
    // const yjpCampaigns = get(data.yjpCampaigns)

    // return getTotalCampaignStats([...fbCampaigns, ...googleCampaigns, ...yjpCampaigns])
  },
})

export const selectTotalCampaignStatsLast = selector({
  key: 'select-total-campaign-stats-last-period',
  get: ({get}) => {
    // const fbCampaigns = get(data.fbCampaignsLast)
    // const googleCampaigns = get(data.googleCampaignsLast)
    // const yjpCampaigns = get(data.yjpCampaignsLast)

    // return getTotalCampaignStats([...fbCampaigns, ...googleCampaigns, ...yjpCampaigns])

    return []
  },
})

export const selectTotalCampaignTrends = selector({
  key: 'select-total-campaign-trend',
  get: ({get}) => {
    // const fbCampaigns = get(data.fbCampaigns)
    // const googleCampaigns = get(data.googleCampaigns)
    // const yjpCampaigns = get(data.yjpCampaigns)
    // const fbCampaignsLast = get(data.fbCampaignsLast)
    // const googleCampaignsLast = get(data.googleCampaignsLast)
    // const yjpCampaignsLast = get(data.yjpCampaignsLast)

    // const results:{
    //   date: string
    //   spend: number
    //   impressions: number
    //   clicks: number
    // }[] = []

    // const allAdData:AdData[] = [
    //   ...fbCampaigns,
    //   ...fbCampaignsLast,
    //   ...googleCampaigns,
    //   ...googleCampaignsLast,
    //   ...yjpCampaigns,
    //   ...yjpCampaignsLast,
    // ]

    // for (const campaign of allAdData) {
    //   // TODO: need to add custom date formatter
    //   const _date = campaign.at.toString().substring(4)
    //   const date = [_date.substring(0,2), _date.substring(2,4)].join('-')
    //   let rec = results.find(d => d.date===date)
    //   if (!rec) {
    //     results.push({date, spend:0, impressions:0, clicks:0 })
    //     rec = results.find(d => d.date===date)
    //   }
    //   rec.spend += campaign.spend
    //   rec.impressions += campaign.impressions
    //   rec.clicks += campaign.clicks
    // }

    return []

    // return results
    //   .sort((a,b) => a.date > b.date ? 1 : -1)
    //   .map(r => ({
    //     date: r.date,
    //     spend: r.spend,
    //     impressions: r.impressions,
    //     clicks: r.clicks,
    //     CPM: roundCurr(r.spend/(r.impressions/1000)),
    //     CPC: roundCurr(r.spend/r.clicks),
    //   }))
  },
})

const getTotalGAStats = (channels:GAData[]) => {
  const stats = {
    users: 0,
    newUsers: 0,
    sessions: 0,
    sessionDuration: 0,
    pageviews: 0,
    bounces: 0,
    transactions: 0,
    revenue: 0,
    addToCarts: 0,
    checkouts: 0,
    bounceRate: 0,
    conversionRate: 0,
    formattedBounceRate: '',
    formattedConversionRate: '',
  }

  // for (const c of channels) {
  //   stats.users += c.users
  //   stats.newUsers += c.newUsers
  //   stats.sessions += c.sessions
  //   stats.sessionDuration += c.sessionDuration
  //   stats.pageviews += c.pageviews
  //   stats.bounces += c.bounces
  //   stats.transactions += c.transactions
  //   stats.revenue += c.revenue
  //   stats.addToCarts += c.addToCarts
  //   stats.checkouts += c.checkouts
  // }
  stats.bounceRate = stats.bounces/stats.sessions
  stats.conversionRate = stats.transactions/stats.sessions
  stats.formattedBounceRate = round(stats.bounceRate*100, 0) + '%'
  stats.formattedConversionRate = round(stats.conversionRate*100, 2) + '%'

  return stats
}

export const selectTotalGAStats = selector({
  key: 'select-total-ga-stats',
  get: ({get}) => {
    const gaChannels = [] //get(data.gaDataChannels)
    return getTotalGAStats(gaChannels)
  },
})

export const selectTotalGAStatsLast = selector({
  key: 'select-total-ga-stats-last-period',
  get: ({get}) => {
    const gaChannels = [] //get(data.gaDataChannelsLast)
    return getTotalGAStats(gaChannels)
  },
})

export const selectTotalGATrends = selector({
  key: 'select-total-ga-trend',
  get: ({get}) => {
    const gaChannels = [] // get(data.gaDataChannels)
    const gaChannelsLast = [] // get(data.gaDataChannelsLast)

    const results:{
      date: string
      bounces: number
      transactions: number
      sessions: number
    }[] = []

    for (const channel of [...gaChannels, ...gaChannelsLast]) {
      // TODO: need to add custom date formatter
      const _date = channel.at.toString().substring(4)
      const date = [_date.substring(0,2), _date.substring(2,4)].join('-')
      let rec = results.find(d => d.date===date)
      if (!rec) {
        results.push({date, bounces:0, transactions:0, sessions:0 })
        rec = results.find(d => d.date===date)
      }
      rec.bounces += channel.bounces
      rec.transactions += channel.transactions
      rec.sessions += channel.sessions
    }

    return results
      .sort((a,b) => a.date > b.date ? 1 : -1)
      .map(r => ({
        date: r.date,
        bounces: r.bounces,
        transactions: r.transactions,
        sessions: r.sessions,
        bounceRate: round(r.bounces/r.sessions, 2),
        conversionRate: round(r.transactions/r.sessions, 2),
      }))
  },
})
