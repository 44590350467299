import { atom, selector } from 'recoil'
import createApp, { ClientApplication } from '@shopify/app-bridge'
import * as log from 'lib/method/log'
import S from '@sh/settings'
import { Store } from '@sh/models/classes'
import { appInstalled as appInstallAtom } from './settings'
import { Buffer } from 'buffer'
import { SavedAuthResponse } from '@sh/models/types/SavedAuth'

export const store = atom({
  key: 'store',
  default: null as Store,
})

export const isFinishedSetup = atom({
  key: 'isFinishedSetup',
  default: false,
})

/**
 * {
 *   hostOrigin: "https://murakamiclinic.myshopify.com"
 *   localOrigin: "https://aidas.1f8.dev"
 * }
 */
let app:ClientApplication<any>
let appShopOrigin = ''

// TODO: host is undefined when using store.getShopHost().
export const clientAppSelector = selector({
  key: 'store-client-app-selector',
  get: ({get}) => {
    const storeObj = get(store)
    if (!storeObj?.id) return null
    const appInstalled = get(appInstallAtom)
    if (!appInstalled) return null

    if (!app || storeObj.id !== appShopOrigin) {
      log.info('creating new app client')
      app = createApp({
        apiKey: S.app.key,
        // host: storeObj.getShopHost(), // this returns undefined for hostOrigin in shopify
        host: Buffer.from(`${storeObj.id}.myshopify.com/admin`).toString('base64'),
        // forceRedirect: true,
      })
      appShopOrigin = storeObj.id
    }

    return app
  },
})
