import { atom } from 'recoil'
import { AdData, EComData, GAData } from '@sh/models/classes'
import { AdAccount } from '@sh/models/interfaces'

export const serviceAccounts = atom<Record<string, AdAccount[]>>({
  key: 'data-ad-account',
  default: {},
})

// SHOPIFY Data

export const shopifyOverview = atom<EComData>({
  key: 'data-shopify-overview',
  default: undefined,
})
export const shopifyOverviewLast = atom<EComData>({
  key: 'data-shopify-overview-last',
  default: undefined,
})
export const shopifyChannels = atom<EComData[]>({
  key: 'data-shopify-channels',
  default: [],
})
export const shopifyChannelsLast = atom<EComData[]>({
  key: 'data-shopify-channels-last',
  default: [],
})

// FACEBOOK

export const fbOverview = atom<AdData>({
  key: 'data-fb-overview',
  default: undefined,
})
export const fbOverviewLast = atom<AdData>({
  key: 'data-fb-overview-last',
  default: undefined,
})
export const fbCampaigns = atom<AdData[]>({
  key: 'data-fb-campaigns',
  default: [],
})
export const fbCampaignsLast = atom<AdData[]>({
  key: 'data-fb-campaigns-last',
  default: [],
})

// GOOGLE ADS

export const googleOverview = atom<AdData>({
  key: 'data-google-overview',
  default: undefined,
})
export const googleOverviewLast = atom<AdData>({
  key: 'data-google-overview-last',
  default: undefined,
})
export const googleCampaigns = atom<AdData[]>({
  key: 'data-google-campaigns',
  default: [],
})
export const googleCampaignsLast = atom({
  key: 'data-google-campaigns-last',
  default: [],
})

// YJP

export const yjpOverview = atom<AdData>({
  key: 'data-jpy-overview3',
  default: undefined,
})
export const yjpOverviewLast = atom<AdData>({
  key: 'data-jpy-overview-last',
  default: undefined,
})
export const yjpCampaigns = atom<AdData[]>({
  key: 'data-jpy-campaigns',
  default: [],
})
export const yjpCampaignsLast = atom<AdData[]>({
  key: 'data-jpy-campaigns-last',
  default: [],
})

// GA

export const gaOverview = atom<GAData>({
  key: 'data-ga-overview',
  default: undefined,
})
export const gaOverviewLast = atom<GAData>({
  key: 'data-ga-overview-last',
  default: undefined,
})
export const gaChannels = atom<GAData[]>({
  key: 'data-ga-channels',
  default: [],
})
export const gaChannelsLast = atom<GAData[]>({
  key: 'data-ga-channels-last',
  default: [],
})
