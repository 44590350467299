/* eslint-disable */
/**
 * Use this lib to inject your actual log implementation
 */

export const info = (...args) => {
  console.log(...args)
}

export const error = (...args) => {
  console.error(...args)
}
