export const round = (n:number, p = 2):number => {
  if (!n) return 0

  const s = n.toString().split('.')
  if (s.length===1 || s[1].length<=p) return n

  const r = Number(s[1].substring(p, p+1))

  const np = n * Math.pow(10, p)
  const b = r < 5 ? Math.floor(np) : Math.ceil(np)

  return b / Math.pow(10, p)
}

export const sum = (ns:number[]):number => ns.reduce((a,b) => a + b, 0)

export const min = (ns:number[]):number|null => ns?.length
  ? ns.reduce((a,b) => Math.min(a,b), ns[0])
  : null

export const max = (ns:number[]):number|null => ns?.length
  ? ns.reduce((a,b) => Math.max(a,b), ns[0])
  : null
