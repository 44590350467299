import React from 'react'
import Recoil from 'recoil'
import Layout from './src/components/Layout'
import './src/styles.css'


export const wrapRootElement = ({ element }) => {
  return (
    <Recoil.RecoilRoot>
      {element}
    </Recoil.RecoilRoot>
  )
}
