import React from 'react'
import enTranslations from '@shopify/polaris/locales/en.json'
import { AppProvider } from '@shopify/polaris'
import { I18nContext, I18nManager } from '@shopify/react-i18n'
import { Amplify } from 'aws-amplify'
import { Helmet } from 'react-helmet'
import { useRecoilValue } from 'recoil'

import * as log from 'lib/method/log'
import * as atoms from 'lib/atoms'
import AmplifyConfig from 'lib/apis/amplify'

// configure amplify API
Amplify.configure({
  API: {
    endpoints: AmplifyConfig.aws_cloud_logic_custom,
  },
})

const Layout = ({ children }) => {
  const lang = useRecoilValue(atoms.lang)
  const store = useRecoilValue(atoms.store)
  const i18nManager = new I18nManager({
    locale: lang,
    onError(error) {
      log.error(error)
    },
  })

  React.useEffect(() => {
    i18nManager.update({ locale:lang })
    // i18nManager isnt a dependency as it shouldnt rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])
  // TODO: replace helmet with Head API after the site is upgraded to gatsby v4
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <link rel='stylesheet' href='https://unpkg.com/@shopify/polaris@6.1.0/dist/styles.css' />
      </Helmet>
      <I18nContext.Provider value={i18nManager}>
        <AppProvider i18n={enTranslations}>
          <div id='root'>
            {/* <ModalMgr /> */}
            { children }
          </div>
        </AppProvider>
      </I18nContext.Provider>
    </>
  )
}

export default Layout
