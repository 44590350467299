// Update the settings in this file
// Settings in this file are meant to be checked into git in order to track changes

export const SERVICE = 'sh-aidas'
export const EDGE_SERVICE = 'sh-aidas-cloudfront'
export const CLOUDFRONT_DISTRIBUTION_ID = 'E15R4X7S67VDRE'

const SETTINGS = {
  app: {
    id: 'aidas',
    name: 'AIDAS',
    key: 'f9411e46a587fa35c43e001ddc63077b',
    callback: 'https://aidas.1f8.dev/install',
  },
  api: {
    domain: 'apis-aidas.1f8.dev',
    region: 'ap-northeast-1',
  },
  scopes: [
    'read_products',
    'read_content',
    'read_locations',
    'read_marketing_events',
    'read_all_orders',
    'read_orders'
  ],
  requestOfflineAccess: true,
  shopifyApi: {
    version: '2022-01',
  },
  fb: {
    url: 'https://graph.facebook.com',
  },
  tables: {
    shops: `${SERVICE}-prod-shops`,
    hooks: `${SERVICE}-prod-hooks`,
  },
  redirecUris: {
    'Facebook': 'facebook',
    'Yahoo Japan': 'yjp',
    'Google Analytics': 'ga',
    'Google Ads': 'google',
    'Shopify': 'shopify',
  }
}

export default SETTINGS
