export enum Integration {
  // this must sync with same values as AdProvider enum
  SHOPIFY = 'Shopify',
  YJP = 'Yahoo Japan',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google Ads',
  INSTAGRAM = 'Instagram',
  TIKTOK = 'TikTok Ads',
  GA = 'Google Analytics',
}
