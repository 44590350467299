import { atom } from 'recoil'
import { DateRange } from '@sh/models/interfaces'
import { LoadingStatus, Screen } from 'lib/enum'
import type { BannerStatus } from '@shopify/polaris'
// const defaultTestShopSelector = ''
// const defaultProgress = true
// const defaultRunning = true
// const defaultModalActive = false
// const defaultModalData = ['']

export interface PopupBase {
  active: boolean
  message: string
}
export interface BannerOption extends PopupBase {
  status: BannerStatus
}

export const loadingData = atom({
  key: 'app-loading-data',
  default: {
    store: LoadingStatus.NotStarted,    // shopify store information
    shopify: {
      total: LoadingStatus.NotStarted,
      totalCompare: LoadingStatus.NotStarted,
      channels: LoadingStatus.NotStarted,
      channelsCompare: LoadingStatus.NotStarted,
    },
    facebook: {
      total: LoadingStatus.NotStarted,
      totalCompare: LoadingStatus.NotStarted,
      campaigns: LoadingStatus.NotStarted,
      campaignsCompare: LoadingStatus.NotStarted,
    },
    google: {
      total: LoadingStatus.NotStarted,
      totalCompare: LoadingStatus.NotStarted,
      campaigns: LoadingStatus.NotStarted,
      campaignsCompare: LoadingStatus.NotStarted,
    },
    yjp: {
      total: LoadingStatus.NotStarted,
      totalCompare: LoadingStatus.NotStarted,
      campaigns: LoadingStatus.NotStarted,
      campaignsCompare: LoadingStatus.NotStarted,
    },
    ga: {
      total: LoadingStatus.NotStarted,
      totalCompare: LoadingStatus.NotStarted,
      channels: LoadingStatus.NotStarted,
      channelsCompare: LoadingStatus.NotStarted,
    },
  },
})

export const selectedDateRange = atom({
  key: 'app-date-range',
  default: {
    start: new Date(),
    end: new Date(),
  } as DateRange,
})

export const selectedCompareDateRange = atom({
  key: 'app-date-range-compare',
  default: {
    start: new Date(),
    end: new Date(),
  } as DateRange,
})

export const screen = atom({
  key: 'app-screen',
  default: Screen.Welcome,
})

export const screenInfo = atom({
  key: 'app-screen-info',
  default: 'Information specific to the screen you\'re seeing will display here',
})

export const selectMobileDate = atom({
  key: 'app-select-mobile-date',
  default: false,
})

export const bannerOpt = atom({
  key: 'app-banner-opt',
  default: {
    active: false,
    message: '',
    status: 'warning',
  } as BannerOption,
})

export const selectSettings = atom({
  key: 'app-select-settings',
  default: false,
})

export const comparisonDate = atom({
  key: 'app-comparison-date',
  default: false,
})

export const toastActive = atom({
  key: 'app-toast-active',
  default: {
    active: false,
    message: '',
  } as PopupBase,
})
export const modalActive = atom({
  key: 'app-modal-active',
  default: {
    active: false,
    message: '',
  } as PopupBase,
})
export const confirmed = atom({
  key: 'app-confirmed',
  default: false,
})
// TODO: allow user to move info bar
// export const infoBarPosition = atom<'top'|'bottom'>({
//   key: 'app-info-bar-position',
//   default: 'top',
// })

// export const progress = atom({
//   key: 'progress',
//   default: defaultProgress,
// })
// export const running = atom({
//   key: 'running',
//   default: defaultRunning,
// })